import { graphql } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import NavHeader from 'blog/components/NavHeader'
//import Posts from 'blog/components/Posts'
import Posts3 from 'blog/components/Posts3'
import Button from 'components/Button'
import Container from 'components/Container'
import Head from 'components/Head'
import Image from 'components/Image'
import Layout from 'components/Layout'
import Text from 'components/Text'
import View from 'components/View'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import enterprise from 'libs/enterprise'
import user from 'libs/user'
import styles from './Blog.module.scss'

type Props = {
  data: unknown
}

export default function Blog({ data }: Props) {
  const { postFeatured: postFeaturedData, postFeaturedFallback, posts: postsWithFeatured } = data
  useEventPageVisited('Blog')
  const globalContext = useGlobalContext()

  // todo remove humpback stuff?
  const isHumpback =
    globalContext.partner === enterprise.HUMPBACK_KEY ||
    user.isEnterpriseHumpbackUser(globalContext.user)

  const postFeatured = _.isEmpty(postFeaturedData.nodes)
    ? postFeaturedFallback.nodes[0]
    : postFeaturedData.nodes[0]
  const posts = postsWithFeatured.nodes
    .filter((item) => {
      if (!item.frontmatter.enterpriseShow?.includes('humpback')) return item

      return isHumpback ? item : undefined
    })
    .filter((item) => {
      if (!item.frontmatter.enterpriseHide?.includes('humpback')) return item

      return isHumpback ? undefined : item
    })
    .filter((item) => item.id !== postFeatured.id)

  return (
    <Layout className="Blog">
      <Head
        title="Exercise, health and wellness tips - The Bold Report"
        description="The latest posts and updates from Bold, your home for personalized, science-backed workouts you can do at home. Read on for exercise, health and wellness tips."
      />
      <NavHeader active="all" />
      <Text color="white" element="header" className={styles['post-featured']}>
        <Container size="xlarge" className={styles['post-featured--inner']}>
          <div className={styles['post-featured--copy']}>
            <div className={styles['post-featured--copy--inner']}>
              <Text
                element="h3"
                size="xxlarge"
                weight="medium"
                className={styles['post-featured--title']}>
                {postFeatured.frontmatter.title}
                {postFeatured.timeToRead && (
                  // TODO
                  <>
                    {'\u00A0'}
                    <Text
                      casing="upper"
                      color="cyan"
                      element="sup"
                      size="large"
                      wrap={false}
                      className={styles['post-featured--time']}>
                      {postFeatured.timeToRead} min read
                    </Text>
                  </>
                )}
              </Text>
              <Button
                to={postFeatured.fields.slug}
                color="purple"
                size="xlarge"
                className={styles['post-featured--button']}>
                Read on
              </Button>
            </div>
          </div>
          <div className={styles['post-featured--image']}>
            <Image src={postFeatured.frontmatter.image} />
          </div>
        </Container>
      </Text>
      <View className={styles.list}>
        <Container flush size="xlarge">
          <Posts3 data={posts} />
        </Container>
      </View>
    </Layout>
  )
}

export const pageQuery = graphql`
  query blogIndex {
    postFeatured: allMdx(
      filter: {
        frontmatter: {
          featured: { eq: true }
          hideFromLanding: { ne: true }
          template: { eq: "blog" }
        }
      }
      limit: 1
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          image {
            #childImageSharp {
            #  fluid(maxWidth: 548, maxHeight: 548, cropFocus: CENTER) {
            #    ...GatsbyImageSharpFluid_withWebp
            #  }
            #}
            childImageSharp {
              fluid(maxWidth: 548) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            publicURL
          }
          title
        }
        timeToRead
      }
    }
    postFeaturedFallback: allMdx(
      filter: { frontmatter: { hideFromLanding: { ne: true }, template: { eq: "blog" } } }
      limit: 1
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          image {
            #childImageSharp {
            #  fluid(maxWidth: 548, maxHeight: 548, cropFocus: CENTER) {
            #    ...GatsbyImageSharpFluid_withWebp
            #  }
            #}
            childImageSharp {
              fluid(maxWidth: 548) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            publicURL
          }
          title
        }
        timeToRead
      }
    }
    posts: allMdx(
      filter: { frontmatter: { template: { eq: "blog" }, hideFromLanding: { ne: true } } }
      #skip: 1
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          enterpriseHide
          enterpriseShow
          image {
            childImageSharp {
              fluid(maxWidth: 924, maxHeight: 536) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
        }
      }
    }
  }
`
